@media (min-width: 1025px) {
    .h-custom {
      height: 100vh !important;
    }
  }
  .card-registration .select-input.form-control[readonly]:not([disabled]) {
    font-size: 1rem;
    line-height: 2.15;
    padding-left: .75em;
    padding-right: .75em;
  }
  .card-registration .select-arrow {
    top: 13px;
  }
  /* .bg-indigo {
    background-color: #25aed0;
  } */
  @media (min-width: 992px) {
    .card-registration-2 .bg-indigo {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  @media (max-width: 991px) {
    .card-registration-2 .bg-indigo {
      border-bottom-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
  }