body {
    overflow: auto !important;
}

.cursor-pointer {
    cursor: pointer;
}

body:not(.modal-open){
    padding-right: 0px !important;
}

.react-datepicker-wrapper-fullwidth > .react-datepicker-wrapper {
    width: 100% !important;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
    color: #0054ca !important;
}