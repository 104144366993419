/* Core */
:root {
    --primary: #FF7000;
    --lightyellow: #FFD5AA;
    --darkblack: #000;
}

.vt-txt-dark-blue {
    color: var(--darkblack);
}

.vt-txt-dark-orange {
    color: var(--primary);
}
 
.vt-primary-btn {
    background-color: var(--primary); 
    color: white; 
    transition: .5s;
    font-weight: bold;
} 
 
.vt-primary-btn:hover {
    text-decoration: none;
    background-color: var(--primary);
    color: white; 
}

/* default fonts */
.fs-8rem {
    font-size: 0.5rem;
}

.fs-9rem {
    font-size: 0.5625rem;
}

.fs-10rem {
    font-size: 0.625rem;
}

.fs-11rem {
    font-size: 0.688rem;
}

.fs-12rem {
    font-size: 0.75rem;
}

.fs-13rem {
    font-size: 0.8125rem;
}

.fs-14rem {
    font-size: 0.875rem;
}

.fs-16rem {
    font-size: 1rem;
}

.fs-20rem {
    font-size: 1.2rem;
}

.fs-22rem {
    font-size: 1.375rem;
}
